// src/pages/Page.js
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { database, ref, get, set, push, update, remove } from '../firebase/firebaseConfig';
import { isMobile } from 'react-device-detect'; // You can install react-device-detect to check for mobile devices
import AddANote from '../components/AddANote';
import Notes from '../components/Notes';
import '../styles/pagenotes.css'

const ITEMS_PER_PAGE = 100;

const PageNotes = ({ user, data, videoIds, setVideoIds, fetchVideoIds, loadFolders, openCardWindowSidebar, closeCardWindowSidebar, activeCardIndex }) => {
    const [editingVideoIndex, setEditingVideoIndex] = useState(null);
    const [newVideoName, setNewVideoName] = useState('');
    const [buttonIndex, setButtonIndex] = useState();

    // Pagination using URL search params
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get('page')) || 1;

    const currentPath = window.location.pathname;

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const [showNotes, setShowNotes] = useState(false);

    useEffect(() => {
        fetchVideoIds();
    }, []);

    // Callback to handle video addition
    const handleVideoAdded = () => {
        fetchVideoIds();
    };
    // END LOAD ALL VIDEOS



    // ADD A NOTE
    const addVideoId = async (newVideoId, noteName, notes = "<h1>" + noteName + "</h1><h2>Subtitle</h2><p><span style='font-size: 12px;'>🔗 Link:&nbsp;</p><p><br/></p><hr><p>📝 Notes</p><hr><p><br/></p><p><br/></p>") => {
        
        console.log("newVideoId: " + newVideoId);
        console.log("noteName: " + noteName);

        // Determine the Firebase path based on the URL
        let firebasePath;
        let folderPath;
        const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'

        firebasePath = `users/${user.uid}/notes/videos/${newVideoId}`;
        folderPath = `users/${user.uid}/notes/videos`;
        // console.log("firebasePath: " + firebasePath);
        // console.log("folderPath: " + folderPath);

        const videoRef = ref(database, firebasePath);
        const videoRefFolder = ref(database, folderPath);

        try {
        // Get all existing videos to determine the next order number
        const snapshot = await get(videoRef);
        const videos = snapshot.exists() ? snapshot.val() : {};

        const snapshotFolder = await get(videoRefFolder);
        const videosFolder = snapshotFolder.exists() ? snapshotFolder.val() : {};

        // Determine the next order number, ensuring it handles an empty videos object
        const maxOrder = videosFolder && Object.keys(videosFolder).length > 0
            ? Math.max(...Object.values(videosFolder).map(video => video.order || 0))
            : 0;
        const newOrder = maxOrder + 1;

        // Create a new note entry
        const newEntry = {
            videoId: newVideoId, notes, order: newOrder, name: noteName
        };

        // Add the new video to Firebase with videoId as the key
        await set(ref(database, firebasePath), newEntry);

        // Update local state (assuming setVideoIds is updating the list of videos)
        setVideoIds(prevVideoIds => [...prevVideoIds, newEntry]);
        } catch (error) {
        console.error('Failed to add video:', error);
        }
    };
    // END ADD A NOTE



// DELETE A NOTE
const deleteVideoId = async (videoIdToDelete) => {
    if(user.uid === "public"){
        window.alert("Please create an account to edit your workspace");
        return;  // Return early to avoid further processing
    }
    else{

        const confirmDelete = window.confirm("Are you sure you want to delete this video and its notes?");

        if (confirmDelete) {
            // Determine the Firebase path based on the URL
            const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'
            let firebasePath = `users/${user.uid}/notes/videos/${videoIdToDelete}`;
            const videoRef = ref(database, firebasePath);

            // Path to the folder containing videos
            let folderPath = `users/${user.uid}/notes/videos`;
            const videoRefFolder = ref(database, folderPath);
            
            try {
                // Get all videos in the folder
                const snapshot = await get(videoRefFolder);
                const videos = snapshot.val(); // Get all videos as an object

                // Delete the specific video
                await remove(videoRef); // Remove the video entry from the database

                const sortedVideos = Object.entries(videos)
                    .sort(([, a], [, b]) => a.order - b.order);

                // Reorder the remaining videos
                let updatedVideos = {};
                let order = 1;

                for (const [key, value] of sortedVideos) {
                    if (key !== videoIdToDelete) { // Skip the deleted video
                        updatedVideos[key] = {
                            ...value,
                            order: order++
                        };
                    }
                }

                // Update the entire videos list with the reordered videos
                await set(videoRefFolder, updatedVideos);
                console.log(`Deleted ${videoIdToDelete} and reordered videos`);

                // Check if this was the last video
                if (order === 1) {
                    await set(videoRefFolder, ""); // Clear the folder if it was the last video
                }

            } catch (error) {
                console.error(`Error deleting ${videoIdToDelete}:`, error);
            }

            // Refresh the list after deletion
            fetchVideoIds();
            loadFolders();
        }
    }
};
// END DELETE A NOTE




const isNameUnique = (name) => {
    return !videoIds.some((video) => name === video.videoId);
};

    // HANDLE CHANGE VIDEO NAME IN SIDEBAR
    const handleVideoEdit = (index, currentName) => {
        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{
            setEditingVideoIndex(index);
            setNewVideoName(currentName);
        }
    };

const handleVideoSave = async (index, oldName, vidId) => {
    if(user.uid === "public"){
        window.alert("Please create an account to edit your workspace");
        return;  // Return early to avoid further processing
    }
    else{
        const formattedNewName = newVideoName.trim();
        const formattedNewKey = formattedNewName.toLowerCase().replace(/\s+/g, '');

        // console.log("formattedNewName: " + formattedNewName);
        // console.log("formattedNewKey: " + formattedNewKey);
        // console.log("oldName: " + oldName);
        // console.log("vidId: " + vidId);

        if (formattedNewName === oldName) {
            setEditingVideoIndex(null);
            return;
        }

        // Check if the new name is unique
        if (!isNameUnique(formattedNewKey)) {
            console.error('A folder with this name already exists.');
            setEditingVideoIndex(null);
            alert('A folder with this name already exists.');
            return;
        }

        const videoId = vidId; // Assuming videoIds has an id property

        let oldFirebasePath;
        let newFirebasePath;

        if (videoId) {
            oldFirebasePath = `users/${user.uid}/notes/videos/${videoId}`;
            newFirebasePath = `users/${user.uid}/notes/videos/${formattedNewKey}`;

            try {

                // Step 1: Fetch data from old path
                const oldDataSnapshot = await get(ref(database, oldFirebasePath));
                const oldData = oldDataSnapshot.val();

                // Step 2: Copy data to new path
                if (oldData) {
                    await update(ref(database, newFirebasePath), oldData);
                }

                // Step 3: Delete old path
                await remove(ref(database, oldFirebasePath));

                // Step 4: Update name at new path
                await update(ref(database, newFirebasePath), { name: formattedNewName, videoId: formattedNewKey });

                // await update(ref(database, videoFirebasePath), { name: formattedNewName });
                
                // console.log('Video name updated in Firebase');
                setEditingVideoIndex(null);
                fetchVideoIds();
            } catch (error) {
                console.error('Error updating video name in Firebase:', error);
            }
        }
    }
};

const handleVideoKeyDown = (e, index, oldName, vidId) => {
    if (e.key === 'Enter') {
        handleVideoSave(index, oldName, vidId);
    }
};
// END - HANDLE CHANGE VIDEO NAME IN SIDEBAR

const handleButtonClick = (index) => {
    openCardWindowSidebar(index);
    setButtonIndex(index);
    setShowNotes(true);
};

const moveVideoUp = async (index) => {
    if(user.uid === "public"){
        window.alert("Please create an account to edit your workspace");
        return;  // Return early to avoid further processing
    }
    else{

        if (index === 0) return; // Can't move up if it's the first item

        const currentPath = window.location.pathname.replace('/pages/', '').replace('/', '');
        const videosRef = ref(database, `users/${user.uid}/notes/videos`);
        const videosSnapshot = await get(videosRef);

        if (videosSnapshot.exists()) {
            const videosData = videosSnapshot.val();
            const sortedVideos = Object.entries(videosData)
                .sort(([, a], [, b]) => a.order - b.order);

            const currentVideo = sortedVideos[index];
            const previousVideo = sortedVideos[index - 1];

            // Swap orders
            await update(ref(database, `users/${user.uid}/notes/videos/${currentVideo[0]}`), { order: previousVideo[1].order });
            await update(ref(database, `users/${user.uid}/notes/videos/${previousVideo[0]}`), { order: currentVideo[1].order });

            // console.log('Video moved up successfully');
            fetchVideoIds(); // Reload videos to reflect the changes
        }
    }
};

const moveVideoDown = async (index) => {
    if(user.uid === "public"){
        window.alert("Please create an account to edit your workspace");
        return;  // Return early to avoid further processing
    }
    else{
        const currentPath = window.location.pathname.replace('/pages/', '').replace('/', '');
        const videosRef = ref(database, `users/${user.uid}/notes/videos`);
        const videosSnapshot = await get(videosRef);

        if (videosSnapshot.exists()) {
            const videosData = videosSnapshot.val();
            const sortedVideos = Object.entries(videosData)
                .sort(([, a], [, b]) => a.order - b.order);

            if (index === sortedVideos.length - 1) return; // Can't move down if it's the last item

            const currentVideo = sortedVideos[index];
            const nextVideo = sortedVideos[index + 1];

            // Swap orders
            await update(ref(database, `users/${user.uid}/notes/videos/${currentVideo[0]}`), { order: nextVideo[1].order });
            await update(ref(database, `users/${user.uid}/notes/videos/${nextVideo[0]}`), { order: currentVideo[1].order });

            // console.log('Video moved down successfully');
            fetchVideoIds(); // Reload videos to reflect the changes
        }
    }
};


    return (
        <>
            <h1>📝 Notes</h1>

            <AddANote 
            user={user}
            onVideoAdded={handleVideoAdded} 
            addVideoId={addVideoId} />

            <Notes
                user={user}
                data={data}
                videoIds={videoIds}
                startIndex={startIndex}
                endIndex={endIndex}
                deleteVideoId={deleteVideoId}
                openCardWindowSidebar={openCardWindowSidebar}
                closeCardWindowSidebar={closeCardWindowSidebar}
                activeCardIndex={activeCardIndex}
            />

            
            {isMobile && <div className="mobile-disclaimer">Double-tap to open a note</div>}

            <ul className="notes">
                {videoIds.map((video, index) => (
                    <>
                        {editingVideoIndex === index ?
                            <>
                                <div className='d-flex align-center list-item'>
                                    <li key={index}>
                                        <input type="text" value={newVideoName} onChange={(e) => setNewVideoName(e.target.value)} onKeyDown={(e) => handleVideoKeyDown(e, index, video.name, video.videoId)} />
                                    </li>
                                    <div className='ml-5 sidebar-btn' onClick={() => handleVideoSave(index, video.name, video.videoId)} data-tooltip="Save"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-save"><path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" /><path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" /><path d="M7 3v4a1 1 0 0 0 1 1h7" /></svg></div>
                                </div>
                            </>
                            :
                            <>
                                <div className='d-flex align-center list-item'>
                                    <li key={index} onClick={() => handleButtonClick(index)}>
                                        {video.name}
                                    </li>
                                    <div className='d-flex align-center icon-container'>
                                        <div className='sidebar-btn pencil mr-5' onClick={() => handleVideoEdit(index, video.name, video.videoId)} data-tooltip="Rename"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg></div>
                                        <div className='sidebar-btn mr-5' onClick={() => deleteVideoId(video.videoId)} data-tooltip="Delete"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg></div>
                                        
                                        <div className='d-flex flex-col justify-center arrows'>
                                            <div className='sidebar-btn arrow-up' onClick={() => moveVideoDown(index)} data-tooltip="Move Up"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6"/></svg></div>
                                            <div className='sidebar-btn arrow-down' onClick={() => moveVideoUp(index)} data-tooltip="Move Down"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                ))}
            </ul>


        </>
    );
};

export default PageNotes;