import React from 'react';
import '../styles/pricingmodal.css';

const PricingModal = ({ user, userData, handleStripeCheckout, closePricingModal }) => {

    // console.log("USER DATA PRICING MODAL: " + userData.subscriptionInfo.plan);

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Select Your Plan</h2>
                <button className="close-button" onClick={closePricingModal}>×</button>

                <div className='d-flex justify-between' style={{columnGap: "20px"}}>

                    <div style={{width: "50%"}}>
                        <div className="pricing-header">
                            <div>Basic</div>
                        </div>
                        <div className="feature-row">
                            <div><b>$14.99</b></div>
                            <div>1 month only</div>
                            {/* <div>Pay As You Go</div> */}
                            <div>Unlimited Storage</div>
                            {/* <div>Good for testing the app</div> */}
                            <br/>
                        </div>

                        {userData.subscriptionInfo.plan === "Basic" ?
                            <>
                                <div className="pricing-buttons">
                                    <button onClick={null} disabled style={{background: "#eee" }}>
                                        Basic Plan
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div className="pricing-buttons">
                                    <button onClick={() => handleStripeCheckout('price_1PxVkxHIFXh7iZZQDakO3X22', 'payment', 'Basic', user.uid)}>
                                        Basic Plan
                                    </button>
                                </div>
                            </>
                        }
                    </div>

                    <div style={{width: "50%"}}>
                        <div className="pricing-header">
                            <div>Subscription</div>
                        </div>
                        <div className="feature-row">
                            <div><b>$10 a month</b></div>
                            <div>Recurring monthly Payments</div>
                            <div>Cancel Anytime</div>
                            <div>Unlimited Storage</div>
                        </div>

                        {userData.subscriptionInfo.plan === "Basic" ?
                        <>
                            <div className="pricing-buttons">
                                <button onClick={null} disabled style={{background: "#eee" }}>
                                    Subscription Plan
                                </button>
                            </div>
                        </>
                        :
                        <>
                            <div className="pricing-buttons">
                                <button onClick={() => handleStripeCheckout('price_1PwFvUHIFXh7iZZQjouOYqrH', 'subscription', 'Subscription', user.uid)}>
                                    Subscription Plan
                                </button>
                            </div>
                        </>
                        }
                    </div>
                </div>

                {/* <button className="close-modal-btn" onClick={closePricingModal}>Close</button> */}
            </div>
        </div>
    );
};

export default PricingModal;
