import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { database, ref, get, set, push, remove } from '../firebase/firebaseConfig';
import { auth } from '../firebase/firebaseConfig';

import AddAVideo from '../components/AddAVideo';
import Cards from '../components/Cards';

// Import your static data
// import homeData from '../data/home.json';
// const featuredData = {
//     "LfaMVlDaQ24": `<h1>"This Is From Featured Data"</h1>`,
//     "Uipmb5F13Us": `<h1>"This Is Video 2"</h1>`
//   };

const ITEMS_PER_PAGE = 100;

function WatchLater({ user,
    videoIds, setVideoIds, fetchVideoIds, loadFolders, openCardWindowSidebar, closeCardWindowSidebar, activeCardIndex,
    folders, pages, setPages, createNewPage, updatePageName, setActiveCardIndex, sidebarOpen, setSidebarOpen, numberOfVideos, checkPlan, freeVideoLimit, handlePricingModal
}) {

    // Pagination using URL search params
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get('page')) || 1;

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    useEffect(() => {
        fetchVideoIds();
        loadFolders();    
    }, []);

    useEffect(() => {
        checkPlan();
    }, [numberOfVideos, freeVideoLimit]);

    // Callback to handle video addition
    const handleVideoAdded = () => {
        fetchVideoIds();
        checkPlan();
    };
    // END LOAD ALL VIDEOS






    
    // ADD A VIDEO
    // const addVideoId = async (newVideoId, notes = "<h1>Title</h1><h2>Subtitle</h2><p><span style='font-size: 11px;'>📹 Video Link:&nbsp;</span><a href='https://www.youtube.com/watch?v=" + newVideoId + "' target='_blank' style='font-size: 11px;'>https://www.youtube.com/watch?v=" + newVideoId + "</a></p><p><br/></p><hr><p>📝 Notes</p><hr><p><br/></p><p><br/></p><p><br/></p>") => {
        const addVideoId = async (newVideoId, notes = `
            <h1>Title</h1>
            <h2>Subtitle</h2>
            <p><span style='font-size: 11px;'>📹 Video Link:&nbsp;</span>
                <a href="https://www.youtube.com/watch?v=${newVideoId}" target='_blank' style='font-size: 12px;'>https://www.youtube.com/watch?v=${newVideoId}</a>
            </p>
            <p>
                <a href="https://chatgpt.com/" target='_blank' style='font-size: 11px;'>🔗 ChatGPT</a>
            </p>
            <p><br/></p>
            <hr>
            <p>📝 Notes</p>
            <hr>
            <p><br/></p><p><br/></p><p><br/></p>`) => {

        // Determine the Firebase path based on the URL
        const homePath = `users/${user.uid}/watchlater/videos/`;
        const videoPath = `${homePath}${newVideoId}`;
        const homeRef = ref(database, homePath);
        const videoRef = ref(database, videoPath);

        try {
        // Check if the /home node exists
        const homeSnapshot = await get(homeRef);

        if (!homeSnapshot.exists()) {
            // Create the /home node if it doesn't exist
            await set(homeRef, {});
        }
        // Get all existing videos to determine the next order number
        const snapshot = await get(videoRef);
        const videos = snapshot.exists() ? snapshot.val() : {};

        const videosHome = homeSnapshot.exists() ? homeSnapshot.val() : {};

        // Determine the next order number, ensuring it handles an empty videos object
        const maxOrder = videosHome && Object.keys(videosHome).length > 0
            ? Math.max(...Object.values(videosHome).map(video => video.order || 0))
            : 0;
        const newOrder = maxOrder + 1;

        // Create a new video entry
        const newEntry = {
            videoId: newVideoId, notes, order: newOrder, name: "Video - " + newVideoId
        };

        // Add the new video to Firebase with videoId as the key
        await set(videoRef, newEntry);

        // Update local state (assuming setVideoIds is updating the list of videos)
        setVideoIds(prevVideoIds => [...prevVideoIds, newEntry]);
        } catch (error) {
        console.error('Failed to add video:', error);
        }
    };
    // END ADD A VIDEO



    
  // DELETE A VIDEO
        const deleteVideoId = async (videoIdToDelete) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this video and its notes?");

        // console.log(videoIdToDelete);
        if (confirmDelete) {
            // Determine the Firebase path based on the URL
            let firebasePath = `users/${user.uid}/watchlater/videos/${videoIdToDelete}`;
            const videoRef = ref(database, firebasePath);

            try {
                await remove(videoRef); // Remove the video entry from the database
                // console.log(`Deleted ${videoIdToDelete} from ${firebasePath}`);
            } catch (error) {
                console.error(`Error deleting ${videoIdToDelete} from ${firebasePath}:`, error);
            }
    
            // Refresh the list after deletion
            fetchVideoIds();
            loadFolders();
            checkPlan();
        }
    };



    return (
        <>
            <h1>🕒 Watch Later</h1>

            {freeVideoLimit == "over"  ? <div className='mb-20'>You have reached the 10 note limit. Please <button onClick={handlePricingModal}>upgrade</button> your plan to add more.</div> : null}
            {freeVideoLimit == "under" ? <AddAVideo user={user} onVideoAdded={handleVideoAdded} addVideoId={addVideoId} /> : null }
            {user.uid == "public" ? <AddAVideo user={user} onVideoAdded={handleVideoAdded} addVideoId={addVideoId} /> : null }

            <Cards
                user={user}
                videoIds={videoIds}
                fetchVideoIds={fetchVideoIds}
                startIndex={startIndex}
                endIndex={endIndex}
                deleteVideoId={deleteVideoId}
                openCardWindowSidebar={openCardWindowSidebar}
                closeCardWindowSidebar={closeCardWindowSidebar}
                activeCardIndex={activeCardIndex}
            />

        </>
    );
};

export default WatchLater;