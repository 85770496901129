import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ref, get, set, update, remove } from 'firebase/database';
import { database } from '../firebase/firebaseConfig'; // Ensure you import your Firebase configuration
import "../styles/sidebar.css";
import { isMobile } from 'react-device-detect'; // You can install react-device-detect to check for mobile devices

function Sidebar({ user, fetchVideoIds, folders, foldersPublic, setPages, loadFolders, videoIds, activeCardIndex, createNewPage, pages, updatePageName, openCardWindowSidebar, sidebarOpen, sidebarClose,
        fetchVideoIdsPublic
}) {
    const [editingIndex, setEditingIndex] = useState(null);
    const [newFolderName, setNewFolderName] = useState('');

    const [editingVideoIndex, setEditingVideoIndex] = useState(null);
    const [newVideoName, setNewVideoName] = useState('');
    const [buttonIndex, setButtonIndex] = useState();

    const inputRef = useRef(null);
    const navigate = useNavigate();

    // Get the current page path
    const currentPath = window.location.pathname;

    // setActiveFolder(currentPath);
    const [activeFolder, setActiveFolder] = useState(currentPath.replace('/pages/', '')); // Default to "Public" on page load

    const [openPublicPagesDropdown, setopenPublicPagesDropdown] = useState(true);
    const [openMyPagesDropdown, setopenMyPagesDropdown] = useState(true);

    const toggleMyPagesDropdown = () => {
        setopenMyPagesDropdown(!openMyPagesDropdown);
    };

    const togglePublicPagesDropdown = () => {
        setopenPublicPagesDropdown(!openPublicPagesDropdown);
    };


    // RENAME: FOLDER RENAME - SIDEBAR
    const handleEditFolder = (index, currentName) => {
        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{
        setEditingIndex(index);
        setNewFolderName(currentName);
        }
    };

    const isNameUnique = (name) => {
        return !folders.some(folder => folder.name === name);
    };

    const handleSave = async (index, oldName, folderKey) => {
        // Log to check if oldName and newName are defined
        const formattedNewName = decodeURIComponent(newFolderName.replace(/\s+/g, '-').trim());
        // const formattedOldName = oldName.replace(/\s+/g, '-').trim();
        const oldFolderKey = decodeURIComponent(folderKey);
        const newFolderKey = decodeURIComponent(newFolderName.replace(/\s+/g, '-').replace('---', '-'))

        // console.log("oldName: " + oldName);
        // console.log("newFolderName: " + newFolderName);
        // console.log("formattedNewName: " + formattedNewName);
        // console.log("folderKey: " + folderKey)
        // console.log("newFolderKey: " + newFolderKey)

        // If the new name is the same as the old name, do nothing
        if (newFolderName === oldName) {
            // console.log('New name is the same as the old name. No changes made.');
            setEditingIndex(null);
            return;
        }

        // Check if the new name is unique
        if (!isNameUnique(newFolderName)) {
            console.error('A folder with this name already exists.');
            setEditingIndex(null);
            alert('A folder with this name already exists.');
            return;
        }

        if (oldName) { // Check if oldName is defined
            const urlName = formattedNewName;
            const oldUrlName = oldName;

            if (newFolderName !== "") {
                const oldFirebasePath = `users/${user.uid}/pages/${oldFolderKey}`;
                const newFirebasePath = `users/${user.uid}/pages/${newFolderKey}`;
                const newFirebasePathName = `users/${user.uid}/pages/${newFolderKey}/name`;

                try {
                    // Step 1: Fetch data from old path
                    const oldDataSnapshot = await get(ref(database, oldFirebasePath));
                    const oldData = oldDataSnapshot.val();

                    // Step 2: Copy data to new path
                    if (oldData) {
                        await update(ref(database, newFirebasePath), oldData);
                    }

                    // Step 3: Delete old path
                    await remove(ref(database, oldFirebasePath));

                    // Step 4: Update name at new path
                    await update(ref(database, newFirebasePath), { name: newFolderName });

                    // console.log('Folder name updated in Firebase');
                    loadFolders();

                    updatePageName(index, newFolderName);
                    setEditingIndex(null);
                    navigate(`/pages/${newFolderKey}`);
                } catch (error) {
                    alert('Folder names cannot contain the following characters\n. # $ [ ]');
                    console.error('Error updating folder name in Firebase:', error);
                }
            }
        } else {
            console.error('Old name is undefined');
        }
    };


    const handleKeyDown = (e, index, oldName, key) => {
        if (e.key === 'Enter') {
            handleSave(index, oldName, key);
        }
    };
    // END - HANDLE CHANGE FOLDER NAME IN SIDEBAR

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (inputRef.current && !inputRef.current.contains(event.target) && editingIndex !== null) {
    //             handleSave(editingIndex, currentPath.replace('/pages/', ''));
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [editingIndex, newFolderName]);  // Add newName to the dependency array



    // RENAME: VIDEO RENAME - SIDEBAR
    const handleVideoEdit = (index, currentName) => {
        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{
            setEditingVideoIndex(index);
            setNewVideoName(currentName);
        }
    };

    const handleVideoSave = async (index, oldName, vidId) => {
        const formattedNewName = newVideoName.trim();

        if (formattedNewName === oldName) {
            setEditingVideoIndex(null);
            return;
        }

        const videoId = vidId; // Assuming videoIds has an id property

        let videoFirebasePath;
        if (videoId) {
            if (currentPath === "/") {
                videoFirebasePath = `users/${user.uid}/home/videos/${videoId}`;
            } else {
                let pagePath;
                pagePath = decodeURIComponent(currentPath)
                videoFirebasePath = `users/${user.uid}/${pagePath}/videos/${videoId}`;
            }

            try {
                await update(ref(database, videoFirebasePath), { name: formattedNewName });
                // console.log('Video name updated in Firebase');
                setEditingVideoIndex(null);
                fetchVideoIds();
            } catch (error) {
                console.error('Error updating video name in Firebase:', error);
            }
        }
    };

    const handleVideoKeyDown = (e, index, oldName, vidId) => {
        if (e.key === 'Enter') {
            handleVideoSave(index, oldName, vidId);
        }
    };
    // END - HANDLE CHANGE VIDEO NAME IN SIDEBAR



    // DELETE: HOME FOLDER VIDEOS
    const handleDeleteHome = async () => {

        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{
            const firebasePathHome = `users/${user.uid}/home/`;
            // Show confirmation dialog
            const isConfirmed = window.confirm('Are you sure you want to delete this folder and all its contents? This can not be recovered.');

            if (!isConfirmed) {
                return; // Exit the function if the user does not confirm
            }
            try {
                await remove(ref(database, firebasePathHome));
                // console.log('Folder deleted from Firebase');
                navigate('/');
                // Remove folder from the local state
                // const updatedFolders = folders.filter(folder => folder !== folderName);
                // setPages(updatedFolders);
                fetchVideoIds();
                loadFolders();
                window.location.reload();
            } catch (error) {
                console.error('Error deleting folder from Firebase:', error);
            }
        }  
    }
    // END - DELETE HOME FOLDER CONTENTS

    // DELETE: NOTES FOLDER VIDEOS
        const handleDeleteNotes = async () => {

            if(user.uid === "public"){
                window.alert("Please create an account to edit your workspace");
                return;  // Return early to avoid further processing
            }
            else{

                const firebasePathHome = `users/${user.uid}/notes/`;
                // Show confirmation dialog
                const isConfirmed = window.confirm('Are you sure you want to delete this folder and all its contents? This can not be recovered.');
        
                if (!isConfirmed) {
                    return; // Exit the function if the user does not confirm
                }
                try {
                    await remove(ref(database, firebasePathHome));
                    // console.log('Folder deleted from Firebase');
                    navigate('/');
                    // Remove folder from the local state
                    // const updatedFolders = folders.filter(folder => folder !== folderName);
                    // setPages(updatedFolders);
                    fetchVideoIds();
                    loadFolders();
                    window.location.reload();
                } catch (error) {
                    console.error('Error deleting folder from Firebase:', error);
                }
            }
        }
        // END - DELETE NOTES FOLDER CONTENTS

        // DELETE: WATCH LATER FOLDER VIDEOS
            const handleDeleteWatchLater = async () => {

                if(user.uid === "public"){
                    window.alert("Please create an account to edit your workspace");
                    return;  // Return early to avoid further processing
                }
                else{

                    const firebasePathWatchLater = `users/${user.uid}/watchlater/`;
                    // Show confirmation dialog
                    const isConfirmed = window.confirm('Are you sure you want to delete this folder and all its contents? This can not be recovered.');
            
                    if (!isConfirmed) {
                        return; // Exit the function if the user does not confirm
                    }
                    try {
                        await remove(ref(database, firebasePathWatchLater));
                        // console.log('Folder deleted from Firebase');
                        navigate('/');
                        // Remove folder from the local state
                        // const updatedFolders = folders.filter(folder => folder !== folderName);
                        // setPages(updatedFolders);
                        fetchVideoIds();
                        loadFolders();
                        window.location.reload();
                    } catch (error) {
                        console.error('Error deleting folder from Firebase:', error);
                    }
                }
            }
            // END - DELETE WATCH LATER FOLDER CONTENTS


    // DELETE: PAGES FOLDER VIDEOS
    const handleDelete = async (folderName) => {

        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{

            const urlName = folderName.replace(/\s+/g, '-');
            const firebasePath = `users/${user.uid}/pages/${urlName}`;

            // Show confirmation dialog
            const isConfirmed = window.confirm('Are you sure you want to delete this folder and all its contents? This can not be recovered.');

            if (!isConfirmed) {
                return; // Exit the function if the user does not confirm
            }

            try {
                await remove(ref(database, firebasePath));
                // console.log('Folder deleted from Firebase');
                navigate('/');
                // Remove folder from the local state
                const updatedFolders = folders.filter(folder => folder !== folderName);
                setPages(updatedFolders);
                navigate(`/`);
                loadFolders();
                // Update the order numbers of all remaining folders
                await updateFolderOrderNumbers();
            } catch (error) {
                console.error('Error deleting folder from Firebase:', error);
            }
        }
    };
    // END - DELETE PAGES FOLDER CONTENTS

    // Update order numbers of all remaining folders
    const updateFolderOrderNumbers = async () => {

        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{

            const pagesRef = ref(database, `users/${user.uid}/pages`);
            const pagesSnapshot = await get(pagesRef);

            if (pagesSnapshot.exists()) {
                const pagesData = pagesSnapshot.val();

                // Sort the pages by their current order to maintain the order they had previously
                const sortedPages = Object.entries(pagesData)
                    .sort(([, a], [, b]) => a.order - b.order);

                // Update Firebase with the new order numbers
                for (let i = 0; i < sortedPages.length; i++) {
                    const pageKey = sortedPages[i][0];  // Correctly get the key for the current page
                    const newOrder = i + 1;
                    const pageRef = ref(database, `users/${user.uid}/pages/${pageKey}`);

                    // Update the order number
                    await update(pageRef, { order: newOrder });
                }

                // console.log('Order numbers updated successfully');
            }
        }
    };

    // DELETE: VIDEO
    const deleteVideoId = async (videoIdToDelete) => {

        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{

        const confirmDelete = window.confirm("Are you sure you want to delete this video and its notes?");

            let firebasePath;
            if (confirmDelete) {
                // Determine the Firebase path based on the URL
                const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'

                if (currentPath === "/") {
                    firebasePath = `users/${user.uid}/home/videos/${videoIdToDelete}`;
                } else {
                    firebasePath = `users/${user.uid}/${pagePath}/videos/${videoIdToDelete}`;
                }

                // let firebasePath = `database/${pagePath}/videos/${videoIdToDelete}`;
                const videoRef = ref(database, firebasePath);

                // Path to the folder containing videos
                let folderPath = `users/${user.uid}/${pagePath}/videos`;
                const videoRefFolder = ref(database, folderPath);

                try {
                    // Get all videos in the folder
                    const snapshot = await get(videoRefFolder);
                    const videos = snapshot.val(); // Get all videos as an object

                    // Delete the specific video
                    await remove(videoRef); // Remove the video entry from the database

                    // Reorder the remaining videos
                    let updatedVideos = {};
                    let order = 1;

                    for (const key in videos) {
                        if (videos[key] && key !== videoIdToDelete) { // Skip the deleted video and null entries
                            updatedVideos[key] = {
                                ...videos[key],
                                order: order++
                            };
                        }
                    }

                    // Update the entire videos list with the reordered videos
                    await set(videoRefFolder, updatedVideos);
                    // console.log(`Deleted ${videoIdToDelete} and reordered videos`);

                    // Check if this was the last video
                    if (order === 1) {
                        await set(videoRefFolder, ""); // Clear the folder if it was the last video
                    }

                } catch (error) {
                    console.error(`Error deleting ${videoIdToDelete}:`, error);
                }

                // Refresh the list after deletion
                fetchVideoIds();
                loadFolders();
            }
        }
    };


    // VIDEO CLICK: OPEN
    const handleButtonClick = (index) => {
        openCardWindowSidebar(index);
        setButtonIndex(index);
    };

    const handleButtonClickClose = (index) => {
        openCardWindowSidebar(index);
        setButtonIndex(-1);
    };

    useEffect(() => {
        // console.log(activeCardIndex);
    }, [activeCardIndex]);

    // MOVE: FOLDERS
    const moveFolderUp = async (index) => {

        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{
            if (index === 0) return; // Can't move up if it's the first item
            const pagesRef = ref(database, `users/${user.uid}/pages`);
            const pagesSnapshot = await get(pagesRef);

            if (pagesSnapshot.exists()) {
                const pagesData = pagesSnapshot.val();
                const sortedPages = Object.entries(pagesData)
                    .sort(([, a], [, b]) => a.order - b.order);

                const currentFolder = sortedPages[index];
                const previousFolder = sortedPages[index - 1];

                // Swap orders
                await update(ref(database, `users/${user.uid}/pages/${currentFolder[0]}`), { order: previousFolder[1].order });
                await update(ref(database, `users/${user.uid}/pages/${previousFolder[0]}`), { order: currentFolder[1].order });

                // console.log('Folder moved up successfully');
                loadFolders(); // Reload folders to reflect the changes
                await updateFolderOrderNumbers();
            }
        }
    };
    const moveFolderDown = async (index) => {

        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{

            const pagesRef = ref(database, `users/${user.uid}/pages`);
            const pagesSnapshot = await get(pagesRef);

            if (pagesSnapshot.exists()) {
                const pagesData = pagesSnapshot.val();
                const sortedPages = Object.entries(pagesData)
                    .sort(([, a], [, b]) => a.order - b.order);

                if (index === sortedPages.length - 1) return; // Can't move down if it's the last item

                const currentFolder = sortedPages[index];
                const nextFolder = sortedPages[index + 1];

                // Swap orders
                await update(ref(database, `users/${user.uid}/pages/${currentFolder[0]}`), { order: nextFolder[1].order });
                await update(ref(database, `users/${user.uid}/pages/${nextFolder[0]}`), { order: currentFolder[1].order });

                // console.log('Folder moved down successfully');
                loadFolders(); // Reload folders to reflect the changes
                await updateFolderOrderNumbers();
            }
        }
    };

    // MOVE: VIDEOS
    const moveVideoUp = async (index) => {

        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{
            if (index === 0) return; // Can't move up if it's the first item
            let firebasePath;
            // Determine the Firebase path based on the URL
            const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'

            if (currentPath === "/") {
                firebasePath = `users/${user.uid}/home/videos`;
            } else {
                firebasePath = `users/${user.uid}/${pagePath}/videos`;
            }
            // Path to the folder containing videos
            const videosRef = ref(database, firebasePath);
            const videosSnapshot = await get(videosRef);

            // console.log(firebasePath);

            if (videosSnapshot.exists()) {
                // Get all videos in the folder
                const videosData = videosSnapshot.val();
                const sortedVideos = Object.entries(videosData)
                    .sort(([, a], [, b]) => a.order - b.order);

                const currentVideo = sortedVideos[index];
                const previousVideo = sortedVideos[index - 1];

                // Swap orders
                await update(ref(database, `${firebasePath}/${currentVideo[0]}`), { order: previousVideo[1].order });
                await update(ref(database, `${firebasePath}/${previousVideo[0]}`), { order: currentVideo[1].order });

                // console.log('Video moved up successfully');
                fetchVideoIds(); // Reload videos to reflect the changes
            }
        }
    };

    const moveVideoDown = async (index) => {

        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  // Return early to avoid further processing
        }
        else{

            let firebasePath;
            // Determine the Firebase path based on the URL
            const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'

            if (currentPath === "/") {
                firebasePath = `users/${user.uid}/home/videos`;
            } else {
                firebasePath = `users/${user.uid}/${pagePath}/videos`;
            }
            // Path to the folder containing videos
            const videosRef = ref(database, firebasePath);
            const videosSnapshot = await get(videosRef);
            // console.log(firebasePath);

            if (videosSnapshot.exists()) {
                const videosData = videosSnapshot.val();
                const sortedVideos = Object.entries(videosData)
                    .sort(([, a], [, b]) => a.order - b.order);

                if (index === sortedVideos.length - 1) return; // Can't move down if it's the last item

                const currentVideo = sortedVideos[index];
                const nextVideo = sortedVideos[index + 1];

                // Swap orders
                await update(ref(database, `${firebasePath}/${currentVideo[0]}`), { order: nextVideo[1].order });
                await update(ref(database, `${firebasePath}/${nextVideo[0]}`), { order: currentVideo[1].order });

                // console.log('Video moved down successfully');
                fetchVideoIds(); // Reload videos to reflect the changes
            }
        }
    };

    // FOLDER CLICK
    const handleFolderClick = (folderName) => {
        fetchVideoIds();
        handleButtonClickClose();
        sidebarClose();
    };

    useEffect(() => {
        const path = decodeURIComponent(currentPath.replace('/pages/', ''));
        setActiveFolder(path);
    }, [currentPath]);


    return (
        <div className={`sidebar ${sidebarOpen ? "" : "sidebar-d-none"}`}>
            {/* <div className="sidebar-close sidebar-btn" data-tooltip="Toggle Sidebar">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-panel-right-open"><rect width="18" height="18" x="3" y="3" rx="2"/><path d="M15 3v18"/><path d="m10 15-3-3 3-3"/></svg>
            </div> */}

            <div className="mobile sidebar-close" onClick={() => {sidebarClose();}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevrons-left"><path d="m11 17-5-5 5-5"/><path d="m18 17-5-5 5-5"/></svg>
            </div>

            <div className="d-flex flex-col tabs sidebar-padding">

                {/* PUBLIC */}
                {/* <div className={`d-flex align-center list-item ${activeFolder === "/public" ? "active" : ""}`}>
                    <Link to="/public" className={`list-item sidebar-home`} onClick={() => handleFolderClick("/public")}><li>Public</li></Link>
                </div> */}

                {/* HOME */}
                <div className={`d-flex align-center list-item
                    inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-full justify-start
                    ${activeFolder === "/" ? "active" : ""}`}>
                    <Link to="/" className={`list-item sidebar-home`} onClick={() => handleFolderClick("/")}><li>🏠 Home
                        {/* <span style={{fontSize: "11px", fontWeight: "300", color: "#666", marginLeft: "5px"}}>(Private)</span> */}
                        </li></Link>
                    <div onClick={() => handleDeleteHome()} className='ml-5 sidebar-btn' data-tooltip="Delete"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg></div>
                </div>

                {/* WATCH LATER */}
                <div className={`d-flex align-center list-item
                    inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-full justify-start
                    ${activeFolder === "/watchlater" ? "active" : ""}`}>
                    <Link to="/watchlater" className={`list-item sidebar-notes`} onClick={() => handleFolderClick("watchlater")}><li>🕒 Watch Later
                        </li></Link>
                        <div onClick={() => handleDeleteWatchLater()} className='ml-5 sidebar-btn' data-tooltip="Delete"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg></div>
                </div>

                {/* NOTES */}
                <div className={`d-flex align-center list-item
                    inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-full justify-start
                    ${activeFolder === "/notes" ? "active" : ""}`}>
                    <Link to="/notes" className={`list-item sidebar-notes`} onClick={() => handleFolderClick("notes")}><li>📝 Notes
                        </li></Link>
                    <div onClick={() => handleDeleteNotes()} className='ml-5 sidebar-btn' data-tooltip="Delete"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg></div>
                </div>

                {/* COMPLETED */}
                {/* <div className={`d-flex align-center list-item ${activeFolder === "/completed" ? "active" : ""}`}>
                    <Link to="/completed" className={`list-item sidebar-notes`} onClick={() => handleFolderClick("completed")}><li>✅ Completed
                        </li></Link>
                </div> */}

            </div>




                {/* PUBLIC FOLDERS */}
                {/* <div className="d-flex flex-col pages sidebar-padding">
                <div className="d-flex align-center justify-between">
                    <div className='sidebar-title text-lg font-semibold tracking-tight' onClick={togglePublicPagesDropdown} style={{ cursor: 'pointer' }}>
                        <div>Public Pages</div>
                        <span style={{ marginLeft: '10px', disply: "flex" }}>
                            {openPublicPagesDropdown ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6"/></svg>}
                        </span>
                    </div>
                </div>

                {openPublicPagesDropdown && (
                <ul className="folders">
                    {foldersPublic.map((folder, index) => (
                        <>
                            {editingIndex === index ?
                                <>

                                    <div className='d-flex align-center list-item'>
                                        <li key={index} onClick={() => { fetchVideoIds(); sidebarClose(); }}>
                                            <input ref={inputRef} type="text" value={newFolderName} onChange={(e) => { setNewFolderName(e.target.value); }} onKeyDown={(e) => handleKeyDown(e, index, folder.name, folder.key)} />
                                        </li>
                                        <div className='ml-5 sidebar-btn' onClick={() => handleSave(index, folder.name, folder.key)} data-tooltip="Save"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-save"><path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" /><path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" /><path d="M7 3v4a1 1 0 0 0 1 1h7" /></svg></div>
                                    </div>

                                </>
                                :
                                <>

                                    <div className={`d-flex align-center list-item ${activeFolder === folder.key ? "active" : ""}`}>
                                        <Link to={`/pages/${decodeURIComponent(folder.key)}`} className={`list-item`} onClick={() => handleFolderClick(folder.key)}>
                                            <li key={index}>
                                                {folder.name}
                                            </li>
                                        </Link>
                                        <div className='icon-container'>
                                            <div className='sidebar-btn' onClick={() => handleEditFolder(index, folder.name, folder.key)} data-tooltip="Rename"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg></div>
                                            <div onClick={() => handleDelete(folder.key)} className='sidebar-btn' data-tooltip="Delete"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg></div>

                                            <div className='d-flex flex-col justify-center'>
                                                <div className='sidebar-btn arrow-up' onClick={() => moveFolderUp(index)} data-tooltip="Move Up"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6" /></svg></div>
                                                <div className='sidebar-btn arrow-down' onClick={() => moveFolderDown(index)} data-tooltip="Move Down"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg></div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                        </>
                    ))}

                    <div className={`d-flex align-center list-item sidebar-btn new-page-btn mt-5 mb-10`} onClick={createNewPage} data-tooltip="Add A Page">
                        <div className='d-flex align-center create-new-page sidebar-btn' style={{ background: "rgba(255,255,255,0)" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-plus"><rect width="18" height="18" x="3" y="3" rx="2" /><path d="M8 12h8" /><path d="M12 8v8" /></svg>
                        </div>
                    </div>
                </ul>
                )}
            </div> */}



            <div className="d-flex flex-col pages sidebar-padding">

                {/* MY FOLDERS */}
                <div className="d-flex align-center justify-between">
                    <div className='sidebar-title text-lg font-semibold tracking-tight' onClick={toggleMyPagesDropdown} style={{ cursor: 'pointer' }}>
                        <div>
                            {/* 📁  */}
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-folder-open"><path d="m6 14 1.5-2.9A2 2 0 0 1 9.24 10H20a2 2 0 0 1 1.94 2.5l-1.54 6a2 2 0 0 1-1.95 1.5H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H18a2 2 0 0 1 2 2v2"/></svg> */}
                            Pages
                            {/* <span style={{fontSize: "11px", fontWeight: "300", color: "#666", marginLeft: "5px"}}>(Private)</span> */}
                            </div>
                        <span style={{ marginLeft: '10px', disply: "flex" }}>
                            {openMyPagesDropdown ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6"/></svg>}
                        </span>
                    </div>
                </div>

                {openMyPagesDropdown && (
                    
                <ul className="folders">
                    {isMobile && <div className="sidebar-mobile-disclaimer">Double-tap to open</div>}
                    {folders.map((folder, index) => (
                        <>
                            {editingIndex === index ?
                                <>

                                    <div className='d-flex align-center list-item'>
                                        <li key={index} className="desktop" onClick={() => { fetchVideoIds(); sidebarClose(); }}>
                                            <input ref={inputRef} type="text" value={newFolderName} onChange={(e) => { setNewFolderName(e.target.value); }} onKeyDown={(e) => handleKeyDown(e, index, folder.name, folder.key)} />
                                        </li>
                                        <li key={index} className="mobile" onClick={() => { fetchVideoIds(); }}>
                                            <input ref={inputRef} type="text" value={newFolderName} onChange={(e) => { setNewFolderName(e.target.value); }} onKeyDown={(e) => handleKeyDown(e, index, folder.name, folder.key)} />
                                        </li>
                                        <div className='ml-5 sidebar-btn save' onClick={() => handleSave(index, folder.name, folder.key)} data-tooltip="Save"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-save"><path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" /><path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" /><path d="M7 3v4a1 1 0 0 0 1 1h7" /></svg></div>
                                    </div>

                                </>
                                :
                                <>

                                    <div className={`d-flex align-center list-item ${activeFolder === folder.key ? "active" : ""}`}>
                                        <Link to={`/pages/${decodeURIComponent(folder.key)}`} className={`
                                            inline-flex items-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 w-full justify-start                                        
                                            `} onClick={() => handleFolderClick(folder.key)}>
                                            <li key={index}>
                                                {folder.name}
                                            </li>
                                        </Link>
                                        <div className='icon-container inline-flex items-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground '>
                                            <div className='sidebar-btn' onClick={() => handleEditFolder(index, folder.name, folder.key)} data-tooltip="Rename"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg></div>
                                            <div onClick={() => handleDelete(folder.key)} className='sidebar-btn delete' data-tooltip="Delete"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg></div>

                                            <div className='d-flex flex-col justify-center'>
                                                <div className='sidebar-btn arrow-up' onClick={() => moveFolderUp(index)} data-tooltip="Move Up"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6" /></svg></div>
                                                <div className='sidebar-btn arrow-down' onClick={() => moveFolderDown(index)} data-tooltip="Move Down"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg></div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                        </>
                    ))}

                    <div className={`d-flex align-center list-item sidebar-btn new-page-btn mb-10`} onClick={createNewPage} data-tooltip="Add A Page">
                        <div className='d-flex align-center create-new-page sidebar-btn' style={{ background: "rgba(255,255,255,0)" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-plus"><rect width="18" height="18" x="3" y="3" rx="2" /><path d="M8 12h8" /><path d="M12 8v8" /></svg>
                        </div>
                    </div>
                </ul>
                )}

            </div>









            <div className={`'sidebar-padding  ${currentPath === "/notes" ? "sidebar-notes" : "sidebar-videos"}`}>
                <div className='sidebar-title mb-2 sidebar-title text-lg font-semibold tracking-tight'>{currentPath === "/notes" ? "Notes" : "Videos"}</div>
                {/* DESKTOP VIDEO NAMES */}
                <ul className={`desktop videos ${currentPath === "/notes" ? "d-flex flex-col-rev" : "" }`}>
                    {videoIds.map((video, index) => (
                        <>
                            {editingVideoIndex === index ?
                                <>
                                    <div className="d-flex align-center list-item">
                                        <li key={index}>
                                            <input type="text" value={newVideoName} onChange={(e) => setNewVideoName(e.target.value)} onKeyDown={(e) => handleVideoKeyDown(e, index, video.name, video.videoId)} />
                                        </li>
                                        <div className='ml-5 sidebar-btn' onClick={() => handleVideoSave(index, video.name, video.videoId)} data-tooltip="Save"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-save"><path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" /><path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" /><path d="M7 3v4a1 1 0 0 0 1 1h7" /></svg></div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={`d-flex align-center list-item flex-col ${index === activeCardIndex ? "active" : null}`}>
                                        <li key={index} onClick={() => handleButtonClick(index)}>
                                            {video.name}
                                        </li>
                                        <div className='d-flex icon-container inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground '>
                                            <div className='sidebar-btn pencil' onClick={() => handleVideoEdit(index, video.name, video.videoId)} data-tooltip="Rename"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg></div>
                                            <div onClick={() => deleteVideoId(video.videoId)} className='sidebar-btn delete-video' data-tooltip="Delete"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg></div>

                                            <div className='d-flex flex-col justify-center arrows'>
                                                <div className='sidebar-btn arrow-up' onClick={() => moveVideoUp(index)} data-tooltip="Move Up"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6" /></svg></div>
                                                <div className='sidebar-btn arrow-down' onClick={() => moveVideoDown(index)} data-tooltip="Move Down"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg></div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    ))}
                </ul>

                {/* MOBILE VIDEO NAMES */}
                <ul className={`mobile videos ${currentPath === "/notes" ? "d-flex flex-col-rev" : "" }`}>
                    {videoIds.map((video, index) => (
                        <>
                            {editingVideoIndex === index ?
                                <>
                                    <div className="d-flex align-center list-item">
                                        <li key={index}>
                                            <input type="text" value={newVideoName} onChange={(e) => setNewVideoName(e.target.value)} onKeyDown={(e) => handleVideoKeyDown(e, index, video.name, video.videoId)} />
                                        </li>
                                        <div className='ml-5 sidebar-btn' onClick={() => handleVideoSave(index, video.name, video.videoId)} data-tooltip="Save"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-save"><path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" /><path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" /><path d="M7 3v4a1 1 0 0 0 1 1h7" /></svg></div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={`d-flex align-center list-item flex-col ${index === activeCardIndex ? "active" : null}`}>
                                        <li key={index} onClick={() => null}>
                                            {video.name}
                                        </li>
                                        <div className='d-flex icon-container inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground '>
                                            <div className='sidebar-btn pencil' onClick={() => handleVideoEdit(index, video.name, video.videoId)} data-tooltip="Rename"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg></div>
                                            <div onClick={() => deleteVideoId(video.videoId)} className='sidebar-btn delete-video' data-tooltip="Delete"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg></div>

                                            <div className='d-flex flex-col justify-center arrows'>
                                                <div className='sidebar-btn arrow-up' onClick={() => moveVideoUp(index)} data-tooltip="Move Up"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6" /></svg></div>
                                                <div className='sidebar-btn arrow-down' onClick={() => moveVideoDown(index)} data-tooltip="Move Down"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg></div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    ))}
                </ul>

            </div>



        </div>
    );
}

export default Sidebar;
