// https://github.com/astroud/pomodoro-react-app
// https://github.com/kevinsqi/react-circular-progressbar


import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable'; // Ensure this is imported
import '../styles/timer.css'; // Ensure to import your CSS file
import Pomodoro from './Pomodoro';
import Stopwatch from './Stopwatch';
import TimerToDo from './TimerToDo';
import { isMobile } from 'react-device-detect'; // You can install react-device-detect to check for mobile devices
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Timer = ({ user, showTimer, toggleShowTimer, logInTime }) => {
  const [time, setTime] = useState(1500); // Time in seconds
  const [isActive, setIsActive] = useState(false);
  const [timerDuration, setTimerDuration] = useState(1500);
  const [startBtn, setStartBtn] = useState(true);
  const [timerTab, setTimerTab] = useState(true);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setTime(prevTime => {
          if (prevTime <= 1) {
            clearInterval(intervalRef.current);
            setIsActive(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isActive]);

  const startTimer = () => setIsActive(true);
  const pauseTimer = () => setIsActive(false);
  const toggleTimer = () => {
    setIsActive(!isActive);
  }
  const resetTimer = () => {
    setIsActive(false);
    setTime(timerDuration);
  };

  const setTimer = (minutes) => {
    setTimerDuration(minutes * 60);
    console.log(time);
    setTime(minutes * 60);
    console.log(time);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const toggleStartBtn = () => {
    setStartBtn(!startBtn);
  }

  const toggleTimerTabs = () => {
    setTimerTab(!timerTab);
  }

  // Calculate progress percentage
  // const progressPercentage = (time / timerDuration) * 100;

  // let progressPercentage;
  //   if(time === 1500){progressPercentage = ((time - 20) / timerDuration) * 100;}
  //   else if(time === 900){progressPercentage = ((time - 12) / timerDuration) * 100;}
  //   else if(time === 600){progressPercentage = ((time - 8) / timerDuration) * 100;}
  //   else if(time === 300){progressPercentage = ((time - 4) / timerDuration) * 100;}
  const progressPercentage = (time / timerDuration) * 100;

  // console.log(time);
  // console.log(timerDuration);

  return (
    <>
    {!isMobile ?
    <Draggable bounds="parent">

      <div className={`timer-container pomodoro ${showTimer ? '' : 'd-none'}`}>
      {/* <div className='logged-in-at'>Logged In at: {logInTime}</div> */}
        <div className="timer-tabs">
          <div className={`pomodoro-tab ${timerTab ? 'tab-active' : ''}`} onClick={toggleTimerTabs}>Pomodoro</div>
          <div className={`stopwatch-tab ${timerTab ? '' : 'tab-active'}`} onClick={toggleTimerTabs}>Stopwatch</div>
        </div>

        <div className="pomodoro-hide" onClick={toggleShowTimer}>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
          {/* Hide */}
        </div>

        <Pomodoro timerTab={timerTab} />
        <Stopwatch timerTab={timerTab} />

        {/* <hr style={{background: "#ccc"}}/> */}

        <TimerToDo user={user}/>

        </div>

    </Draggable>

      :
      
    <div className={`mobile-checked timer-container pomodoro ${showTimer ? '' : 'd-none'}`}>
      {/* <div className='logged-in-at'>Logged In: {logInTime}</div> */}
      <div className="timer-tabs">
        <div className={`pomodoro-tab ${timerTab ? 'tab-active' : ''}`} onClick={toggleTimerTabs}>Pomodoro</div>
        <div className={`stopwatch-tab ${timerTab ? '' : 'tab-active'}`} onClick={toggleTimerTabs}>Stopwatch</div>
      </div>

      <div className="pomodoro-hide" onClick={toggleShowTimer}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
        {/* Hide */}
      </div>

      <Pomodoro timerTab={timerTab} />
      <Stopwatch timerTab={timerTab} />

      {/* <hr style={{background: "#ccc"}}/> */}

      <TimerToDo user={user}/>

      </div>
    }

  </>
  );
};

export default Timer;
