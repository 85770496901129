// src/pages/Page.js
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { database, ref, get, set, push, remove } from '../firebase/firebaseConfig';

import Sidebar from '../components/Sidebar';
import AddAVideo from '../components/AddAVideo';
import Cards from '../components/Cards';







const ITEMS_PER_PAGE = 100;

const Page = ({ user,
    videoIds, folders, setVideoIds, fetchVideoIds, loadFolders, openCardWindowSidebar, closeCardWindowSidebar, activeCardIndex, 
    pages, setPages, createNewPage, updatePageName, setActiveCardIndex, sidebarOpen, setSidebarOpen, numberOfVideos, checkPlan, freeVideoLimit, handlePricingModal
}) => {
    // Pagination using URL search params
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get('page')) || 1;

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const currentPath = window.location.pathname;

    useEffect(() => {
        fetchVideoIds();
        loadFolders();
    }, [currentPath]);

    useEffect(() => {
        checkPlan();
    }, [numberOfVideos, freeVideoLimit]);

    // Callback to handle video addition
    const handleVideoAdded = () => {
        fetchVideoIds();
        checkPlan();
    };
    // END LOAD ALL VIDEOS


    // ADD A VIDEO
    // const addVideoId = async (newVideoId, notes = "<h1>Title</h1><h2>Subtitle</h2><p><span style='font-size: 11px;'>📹 Video Link:&nbsp;</span><a href='https://www.youtube.com/watch?v=" + newVideoId + "' target='_blank' style='font-size: 11px;'>https://www.youtube.com/watch?v=" + newVideoId + "</a></p><p><br/></p><hr><p>📝 Notes</p><hr><p><br/></p><p><br/></p>") => {

        const addVideoId = async (newVideoId, notes = `
            <h1>Title</h1>
            <h2>Subtitle</h2>
            <p><span style='font-size: 12px;'>📹 Video Link:&nbsp;</span>
                <a href="https://www.youtube.com/watch?v=${newVideoId}" target='_blank' style='font-size: 12px;'>https://www.youtube.com/watch?v=${newVideoId}</a>
            </p>
            <p>
                <a href="https://chatgpt.com/" target='_blank' style='font-size: 12px;'>🔗 ChatGPT</a>
            </p>
            <p><br/></p>
            <hr>
            <p>📝 Notes</p>
            <hr>
            <p><br/></p><p><br/></p><p><br/></p>`) => {

        // Determine the Firebase path based on the URL
        let firebasePath;
        let folderPath;
        const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'

        firebasePath = `users/${user.uid}/${pagePath}/videos/${newVideoId}`;
        folderPath = `users/${user.uid}/${pagePath}/videos`;
        // console.log("firebasePath: " + firebasePath);
        // console.log("folderPath: " + folderPath);

        const videoRef = ref(database, firebasePath);
        const videoRefFolder = ref(database, folderPath);

        try {
        // Get all existing videos to determine the next order number
        const snapshot = await get(videoRef);
        const videos = snapshot.exists() ? snapshot.val() : {};

        const snapshotFolder = await get(videoRefFolder);
        const videosFolder = snapshotFolder.exists() ? snapshotFolder.val() : {};

        // Determine the next order number, ensuring it handles an empty videos object
        const maxOrder = videosFolder && Object.keys(videosFolder).length > 0
            ? Math.max(...Object.values(videosFolder).map(video => video.order || 0))
            : 0;
        const newOrder = maxOrder + 1;

        // Create a new video entry
        const newEntry = {
            videoId: newVideoId, notes, order: newOrder, name: "Video - " + newVideoId
        };

        // Add the new video to Firebase with videoId as the key
        await set(ref(database, firebasePath), newEntry);

        // Update local state (assuming setVideoIds is updating the list of videos)
        setVideoIds(prevVideoIds => [...prevVideoIds, newEntry]);
        } catch (error) {
        console.error('Failed to add video:', error);
        }
    };
    // END ADD A VIDEO



// DELETE A VIDEO
const deleteVideoId = async (videoIdToDelete) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this video and its notes?");

    if (confirmDelete) {
        // Determine the Firebase path based on the URL
        const pagePath = decodeURIComponent(currentPath.substring(1)); // This will give 'pages/new-page-1'
        let firebasePath = `users/${user.uid}/${pagePath}/videos/${videoIdToDelete}`;
        const videoRef = ref(database, firebasePath);

        // Path to the folder containing videos
        let folderPath = `users/${user.uid}/${pagePath}/videos`;
        const videoRefFolder = ref(database, folderPath);
        
        try {
            // Get all videos in the folder
            const snapshot = await get(videoRefFolder);
            const videos = snapshot.val(); // Get all videos as an object

            // Delete the specific video
            await remove(videoRef); // Remove the video entry from the database

            // Reorder the remaining videos
            let updatedVideos = {};
            let order = 1;

            for (const key in videos) {
                if (videos[key] && key !== videoIdToDelete) { // Skip the deleted video and null entries
                    updatedVideos[key] = {
                        ...videos[key],
                        order: order++
                    };
                }
            }

            // Update the entire videos list with the reordered videos
            await set(videoRefFolder, updatedVideos);
            console.log(`Deleted ${videoIdToDelete} and reordered videos`);

            // Check if this was the last video
            if (order === 1) {
                await set(videoRefFolder, ""); // Clear the folder if it was the last video
            }

        } catch (error) {
            console.error(`Error deleting ${videoIdToDelete}:`, error);
        }

        // Refresh the list after deletion
        fetchVideoIds();
        loadFolders();
        checkPlan();
    }
};

let decodedKey;
let decodedCurrentPath;
let pageTitle;

folders.map((folder, index) => {
    decodedKey = decodeURIComponent(folder.key);
    decodedCurrentPath = decodeURIComponent(currentPath.replace('/pages/', ''));
    if(decodedKey === decodedCurrentPath){
        pageTitle = folder.name;
    };
})


    return (
        <>
            <h1>{pageTitle}</h1>

            {freeVideoLimit == "over"  ? <div className='mb-20'>You have reached the 10 note limit. Please <button onClick={handlePricingModal}>upgrade</button> your plan to add more.</div> : null}
            {freeVideoLimit == "under" ? <AddAVideo user={user} onVideoAdded={handleVideoAdded} addVideoId={addVideoId} /> : null }
            {user.uid == "public" ? <AddAVideo user={user} onVideoAdded={handleVideoAdded} addVideoId={addVideoId} /> : null }

            <Cards
                user={user}
                videoIds={videoIds}
                startIndex={startIndex}
                endIndex={endIndex}
                deleteVideoId={deleteVideoId}
                fetchVideoIds={fetchVideoIds}
                openCardWindowSidebar={openCardWindowSidebar}
                closeCardWindowSidebar={closeCardWindowSidebar}
                activeCardIndex={activeCardIndex}
            />
        </>
    );
};

export default Page;