import React, { useState, useEffect } from 'react';
import { database, ref, get, set, push, remove, update } from '../firebase/firebaseConfig';
import '../styles/timertodolist.css';

const TimerToDo = ({ user }) => {
    const [tasks, setTasks] = useState([]);
    const [editTaskId, setEditTaskId] = useState(null);
    const [editTaskText, setEditTaskText] = useState('');

    // Fetch the existing list from Firebase
    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const firebasePath = `users/${user.uid}/todolist`;
                const toDoRef = ref(database, firebasePath);
                const snapshot = await get(toDoRef);

                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const tasksList = Object.keys(data).map((key) => ({
                        id: key,
                        text: data[key].text,
                    }));
                    setTasks(tasksList);
                } else {
                    setTasks([]);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, [user]);

    // Add a new task with default text "New Task"
    const handleAddTask = async () => {
        if(user.uid === "public"){
            window.alert("Please create an account to edit your workspace");
            return;  
        }
        else{
            try {
                const firebasePath = `users/${user.uid}/todolist`;
                const toDoRef = ref(database, firebasePath);
                const newTaskRef = push(toDoRef); // Creates a unique ID for the new task

                const taskData = { text: 'New Task' }; // Default text for the new task
                await set(newTaskRef, taskData);

                // Add the new task locally
                setTasks([...tasks, { id: newTaskRef.key, text: 'New Task' }]);
            } catch (error) {
                console.error('Error adding task:', error);
            }
        }
    };

    // Edit a task
    const handleEditTask = (id, text) => {
        setEditTaskId(id);
        setEditTaskText(text);
    };

    const handleSaveEditTask = async (id) => {
        try {
            const firebasePath = `users/${user.uid}/todolist/${id}`;
            const taskRef = ref(database, firebasePath);

            await update(taskRef, { text: editTaskText });

            // Update the task locally
            const updatedTasks = tasks.map((task) =>
                task.id === id ? { ...task, text: editTaskText } : task
            );
            setTasks(updatedTasks);

            setEditTaskId(null); // Exit edit mode
            setEditTaskText(''); // Clear edit input
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    // Save the task when pressing Enter or losing focus
    const handleInputKeyPress = (e, taskId) => {
        if (e.key === 'Enter') {
            handleSaveEditTask(taskId);
        }
    };

    // Delete a task
    const handleDeleteTask = async (id) => {
        try {
            const firebasePath = `users/${user.uid}/todolist/${id}`;
            const taskRef = ref(database, firebasePath);

            await remove(taskRef);

            // Remove the task locally
            const updatedTasks = tasks.filter((task) => task.id !== id);
            setTasks(updatedTasks);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    return (
        <div className="timer-to-do-list">
            <div className="to-do-title">To Do List</div>

            {/* List of Tasks */}
            <ul>
                {tasks.map((task) => (
                    <li key={task.id} className="task-item">
                        {editTaskId === task.id ? (
                            // Edit Mode
                            <>
                                <input
                                    type="text"
                                    value={editTaskText}
                                    onChange={(e) => setEditTaskText(e.target.value)}
                                    onBlur={() => handleSaveEditTask(task.id)} // Save when input loses focus
                                    onKeyPress={(e) => handleInputKeyPress(e, task.id)} // Save on Enter press
                                    autoFocus
                                />
                            </>
                        ) : (
                            // Display Task
                            <>
                                <span onClick={() => handleEditTask(task.id, task.text)}>
                                    {task.text}
                                </span>
                                <div className="d-flex edit-buttons">
                                    <button onClick={() => handleDeleteTask(task.id)} className="list-delete">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
                                        {/* Delete */}
                                    </button>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ul>
            <div className="add-task" onClick={handleAddTask}>
                <div className="add-task-plus">+</div>
            </div>
        </div>
    );
};

export default TimerToDo;
