import React, { useState, useEffect } from 'react';

const Stopwatch = ({timerTab}) => {
  const [time, setTime] = useState(0); // Elapsed time in milliseconds
  const [isRunning, setIsRunning] = useState(false); // To check if stopwatch is running
  const [intervalId, setIntervalId] = useState(null); // To store the interval ID
  const [stopwatchStart, setStopwatchStart] = useState(false);

  useEffect(() => {
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [intervalId]);

  const startStopwatch = () => {
    if (!isRunning) {
      const newIntervalId = setInterval(() => {
        setTime(prevTime => prevTime + 10); // Increment time by 10ms for smoother stopwatch
      }, 10); // Update every 10000 milliseconds (1 sec)
      setIntervalId(newIntervalId);
      setIsRunning(true);
    }
  };

  const stopStopwatch = () => {
    if (isRunning) {
      clearInterval(intervalId);
      setIsRunning(false);
    }
  };

  const toggleStopwatch = () => {

    if(stopwatchStart === false){
      startStopwatch()
    }else{stopStopwatch()
    }

    setStopwatchStart(!stopwatchStart);
  }

  const resetStopwatch = () => {
    clearInterval(intervalId);
    setTime(0);
    setIsRunning(false);
    setStopwatchStart(false);
  };

  // Format time into minutes and seconds (MM:SS)
  const formatTime = () => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  };

  return (
    <div className={`${timerTab ? 'd-none' : 'd-flex justify-center'}`} style={{marginBottom: "40px"}}>
      <div className='stopwatch'>
        <div className='time' onClick={toggleStopwatch}>{formatTime()}</div>

        <div className="start-container">
            {stopwatchStart ?
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pause"><rect x="14" y="4" width="4" height="16" rx="1" /><rect x="6" y="4" width="4" height="16" rx="1" /></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-play"><polygon points="6 3 20 12 6 21 6 3" /></svg>                
            }
        </div>

        <button className="stopwatch-clear" onClick={resetStopwatch}>Clear</button>


        <div className='d-flex justify-center d-none' style={{columnGap: "2px"}}>

          <button onClick={startStopwatch}>Start</button>
          <button onClick={stopStopwatch}>Stop</button>
          <button className="stopwatch-clear" onClick={resetStopwatch}>Clear</button>

        </div>
      </div>
    </div>
  );
};

export default Stopwatch;
