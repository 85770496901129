import React, { useState, useEffect } from 'react';
import { database, ref, onValue, auth, remove } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { signOut, deleteUser, createUserWithEmailAndPassword, signInWithEmailAndPassword, reauthenticateWithCredential, reauthenticateWithPopup, EmailAuthProvider, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, } from 'firebase/auth';
import "../styles/myaccount.css";
import DeleteAccountButton from './DeleteAccountButton';
import CancelSubscriptionButton from './CancelSubscriptionButton';

const MyAccount = ({ user, userData, setUser, myAccountOpen, toggleMyAccount, handleSignOut, handlePricingModal }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
    const [subscriptionId, setSubscriptionId] = useState(null);
    // const [userTwo, setUserTwo] = useState(null);

    
    useEffect(() => {
        const fetchSubscriptionId = async () => {
            const firebasePath = `users/${user.uid}/subscriptionInfo/subscriptionId`;
            const subscriptionRef = ref(database, firebasePath);
    
            // Use onValue for real-time data listener in Firebase v9
            onValue(subscriptionRef, (snapshot) => {
                const subscriptionId = snapshot.val();
                if (subscriptionId) {
                    // console.log("Subscription ID: " + subscriptionId);
                    setSubscriptionId(subscriptionId);
                }
            });
        };
    
        fetchSubscriptionId();
    }, [user.uid]);




    //     // Get the current authenticated user
    //     useEffect(() => {
    //         const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    //             if (currentUser) {
    //                 setUser(currentUser);
    //             }
    //         });
    
    //         return () => unsubscribe(); // Cleanup listener on component unmount
    //     }, []);


    // // Function to delete user's data and account
    // const handleDeleteAccount = async () => {
    //     if (user) {
    //         // Show confirmation dialog
    //         const isConfirmed = window.confirm('Are you sure you want to delete your account and all its data? This cannot be recovered.');

    //         if (!isConfirmed) {
    //             return; // Exit the function if the user does not confirm
    //         }

    //         try {
    //             const providerId = user.providerData[0]?.providerId; // Get the user's authentication provider

    //             if (providerId === 'password') {
    //                 // For email/password users, prompt for password reauthentication
    //                 const password = prompt('Please enter your password to confirm account deletion:');
    //                 if (password) {
    //                     const credential = EmailAuthProvider.credential(user.email, password);
    //                     await reauthenticateWithCredential(user, credential);
    //                 } else {
    //                     console.log('Password not provided. Account deletion canceled.');
    //                     return;
    //                 }
    //             } else if (providerId === 'google.com') {
    //                 // For Google users, reauthenticate using Google popup
    //                 const provider = new GoogleAuthProvider();
    //                 await reauthenticateWithPopup(user, provider);
    //             }

    //             // Remove user's data from Firebase Realtime Database
    //             const userRef = ref(database, `users/${user.uid}`);
    //             await remove(userRef);
    //             console.log(`User data for UID ${user.uid} removed from the database.`);

    //             // Delete the user's Firebase Auth account
    //             await deleteUser(user);
    //             console.log(`User account for UID ${user.uid} deleted from Firebase Authentication.`);

    //             // Sign out the user and navigate to the home page
    //             await signOut(auth);
    //             navigate('/deletesuccess'); // Redirect to the home page after account deletion
    //         } catch (error) {
    //             console.error('Error deleting account:', error);
    //         }
    //     }
    // };





    // const handleDeleteAccountClick = () => {
    //     if (subscriptionId) {
    //         setErrorMessage('Please cancel your subscription before deleting your account.');
    //     } else {
    //         // Logic to delete the account
    //         handleDeleteAccount();
    //     }
    // };


    return (
        <>
            <div className={`my-account-menu ${myAccountOpen ? 'd-flex' : 'd-none'}`}>
                <div className="d-flex flex-col" style={{color: "rgba(0, 0, 0, .7)"}}>
                    <div>Logged in as user: </div>
                    <div>{user.email}</div>
                    <div className='mt-5'>Current Plan: {userData?.subscriptionInfo?.plan}</div>
                </div>
                <hr style={{width: "100%"}}/>
                {/* UPGRADE */}
                {userData?.subscriptionInfo?.plan === "Basic" ?
                            <>
                            <div className="upgrade-disclaimer">Please wait until your current account ends to upgrade</div>
                            <button id="upgrade" className="" onClick={null} disabled>Upgrade</button>
                            </>
                : userData?.subscriptionInfo?.plan === "Subscription" ?
                <>
                    <button id="upgrade" className="" onClick={null} disabled>Upgrade</button>
                </>
                : userData?.subscriptionInfo?.plan !== "Subscription" ?
                    <>
                        <button id="upgrade" className="" onClick={handlePricingModal}>Upgrade</button>
                    </>
                    : null}
                

                {userData?.subscriptionInfo?.plan === "Subscription" ? (
                    subscriptionId && !userData?.subscriptionInfo?.canceldate ? (

                        <CancelSubscriptionButton user={user} subscriptionId={subscriptionId} />
                    ) : (
                        <CancelSubscriptionButton user={user} subscriptionId={subscriptionId} disabled={"disabled"} tooltip={"Searching For Subscription ID"} />
                    )
                ) : null}

                {userData?.subscriptionInfo?.plan === "Subscription" && !userData?.subscriptionInfo?.canceldate ?
                <DeleteAccountButton disabled={"disabled"} user={user} setUser={setUser} />
                :
                <DeleteAccountButton user={user} setUser={setUser} />
                }

                {errorMessage && <p className="error-message">{errorMessage}</p>}

                <hr style={{width: "100%"}}/>
                <div>
                    <div className="contact-nav" ><a href="mailto:noteworkspaceapp@gmail.com"  style={{color: "black"}}>Contact</a></div>
                    <div className="upgrade-disclaimer"  style={{marginTop: "0px"}}>noteworkspaceapp@gmail.com</div>
                </div>
                <hr style={{width: "100%"}}/>
                <div className="contact-nav" onClick={toggleMyAccount}>Close</div>

            </div>
        </>
    );
};

export default MyAccount;
