import React, { useState, useEffect } from 'react';
import { auth, database, ref, remove } from '../firebase/firebaseConfig';
import { signOut, deleteUser, onAuthStateChanged, reauthenticateWithCredential, EmailAuthProvider, GoogleAuthProvider, reauthenticateWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function DeleteAccountButton({disabled, user, setUser}) {
    const navigate = useNavigate();


        // Get the current authenticated user
        useEffect(() => {
            const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
                if (currentUser) {
                    setUser(currentUser);
                }
            });
    
            return () => unsubscribe(); // Cleanup listener on component unmount
        }, []);


    // Function to delete user's data and account
    const handleDeleteAccount = async () => {
        if (user) {
            // Show confirmation dialog
            const isConfirmed = window.confirm('Are you sure you want to delete your account and all its data? This cannot be recovered.');

            if (!isConfirmed) {
                return; // Exit the function if the user does not confirm
            }

            try {
                const providerId = user.providerData[0]?.providerId; // Get the user's authentication provider

                if (providerId === 'password') {
                    // For email/password users, prompt for password reauthentication
                    const password = prompt('Please enter your password to confirm account deletion:');
                    if (password) {
                        const credential = EmailAuthProvider.credential(user.email, password);
                        await reauthenticateWithCredential(user, credential);
                    } else {
                        // console.log('Password not provided. Account deletion canceled.');
                        return;
                    }
                } else if (providerId === 'google.com') {
                    // For Google users, reauthenticate using Google popup
                    const provider = new GoogleAuthProvider();
                    await reauthenticateWithPopup(user, provider);
                }

                // Remove user's data from Firebase Realtime Database
                const userRef = ref(database, `users/${user.uid}`);
                await remove(userRef);
                // console.log(`User data for UID ${user.uid} removed from the database.`);

                // Delete the user's Firebase Auth account
                await deleteUser(user);
                // console.log(`User account for UID ${user.uid} deleted from Firebase Authentication.`);

                // Sign out the user and navigate to the home page
                await signOut(auth);
                navigate('/deletesuccess'); // Redirect to the home page after account deletion
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        }
    };



    return (
    <>
        {disabled === "disabled" && <div style={{fontSize: "10px"}}>You must cancel your subscription <br/> before deleting your account</div>}
        <button onClick={() => {handleDeleteAccount();}} disabled={disabled} className={`delete-account-button ${disabled ? 'disabled' : ''}`}>
            Delete Account
        </button>
    </>
    );
}

export default DeleteAccountButton;
